import { createRouter, createWebHashHistory } from 'vue-router'
import 'nprogress/nprogress.css'

const routes = [
  {
    path: '/',
    name: 'editor',
    component: () => import('@/views/editor.vue')
  },
  {
    path: '/:w*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
export default router
