<template>
  <el-config-provider>
    <router-view></router-view>
  </el-config-provider>
</template>
<script>
</script>
<style lang="scss" scoped>

</style>
